

/* menu start */
.menu {
  background-color: var(--primary);
  color: var(--light);
  position: fixed;
  width: 100%;
  z-index: 101;
}
.menu__title {
  position: fixed;
  right: 43%;
  top: 0;
  height: 48px;
  display: flex;
  align-items: center;
}
.menu__items {
  position: fixed;
  width: 100%;
  background-color: var(--primary);
  bottom: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row-reverse;
  padding: 0 10px;
  padding-right: 4vw;

  z-index: 50;
  height: 40px;
}
.menu__logo {
  width: 48px;
  padding: 8px;
}

.menu__item {
  display: flex;
  align-items: center;
}

.menu__item__text {
  display: none;
  color: white !important;
  width: 100%;
  padding-right: 10px;
}
.menu__item__icon {
  display: block !important;
  font-size: 1.3rem;
  padding: 0 !important;
}
.menu__item__text__drop {
  display: none;
}
.menu__item .dropdown .btn {
  border: none;
}
.menu_account .menu__item__icon {
  display: block;
}
.menu_account .menu__item__text {
  display: none;
}
.menu__item .dropdown-item {
  font-size: 1.5rem;
  padding-top: 0;
  padding-bottom: 0;
}
.dropdown,
.dropdown-menu {
  background-color: none !important;
  color: white !important;
  width: fit-content;
}
.dropdown-default {
  font-size: 1.5rem;
  font-weight: 300;
}
.dropdown-toggler {
  display: flex;
  justify-content: space-between;
  padding: 50px;
}
.temp-slider {
  background-color: var(--primary);
  width: 50vw !important;
  position: absolute;
  bottom: 6vh;
  justify-content: center;
}
.temprature_value {
  position: absolute !important;
  left: 40vw !important;
  bottom: 0.2vh;
}
.saved__list {
  overflow: hidden;
  width: 100%;
  margin-top: 10px;
  cursor: pointer;
  padding: 0 10px;
}
.saved__list:hover {
  border-left: 1px solid var(--light);
}
.saved__list__items {
  height: 50vh !important;
  overflow-y: scroll;
  width: 100%;
  margin: 0;
  background: var(--primary);
  position: absolute;
  bottom: 30px;
}
.modal-backdrop {
  --bs-backdrop-zindex: 100 !important;
}
.saved__title {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap; 
  overflow: hidden; 
}
.saved__list:hover {
  display: flex;
}
.saved__delete__btn {
  display: none !important;
}
.saved__list:hover .saved__delete__btn {
  display: block !important;
}

.saved__delete__btn__image {
  width: 25px;
  padding: 5px;
}
.back #savedBack {
  display: none !important;
}

#hide {
  display: none !important;
}
.back #hide {
  display: block !important;
}
.menu__item__text .hide {
  display: none !important;
}
#back_to_home {
  display: none !important;
}
/* menu end */

/* body start */
.body {
  background-color: var(--back);
  min-height: 100vh;
}

.body__menu {
  width: 33vw;
  background-color: var(--primary);
  color: var(--light);
  position: fixed;
  bottom: 0;
  right: 0;
  height: 43px;
}
.body__btn {
  align-items: center;
  cursor: pointer;
  background-color: var(--light);
  display: flex;
  margin: 0 5%;
  width: 50px;
  height: 50px;
  padding: 5px;
  color: var(--primary);
  border-radius: 10px;
}
.body__btn__text {
  display: none;
}
.body__btn__icon {
  font-size: 1.5rem;
}
.body__mode {
  color: var(--light);
  position: fixed;
  top: 0;
  right: 0;
  height: 48px;
  display: flex;
  cursor: pointer;
  z-index: 102;
}
.body__mode__icon {
  font-size: 0.8rem;
}
.body__chat {
  padding: 10px 20px;
  padding-bottom: 150px;
  margin-top: 20px;
}
.body__chat__question__icon img,
.body__chat__answer__icon img {
  width: 35px;
  padding-right: 10px;
  margin-bottom: 15px;
}
.body__chat__question__text,
.body__chat__answer__text {
  margin-bottom: 15px;
  width: 100%;
  padding: 10px;
  line-height: 1.5rem;
}
.body__chat__question__text {
  background-color: var(--primary);
  color: var(--light);
  border-top-right-radius: 25px;
}
.body__chat__answer__text {
  background-color: var(--answer-back);
  color: var(--text-answer);
  border-bottom-right-radius: 25px;
}

.body__chat__wellcome .body__chat__answer__text {
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
}

.body__chat__answer__mini img {
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  padding: 0;
  float: right;
  padding-right: 15px;
}
.body__bottom {
  position: fixed;
  bottom: 40px;
  padding: 15px;
  width: 100%;
  z-index: 5;
  background-color: var(--back);
}
.body__bottom__input {
  width: 100%;
  background-color: var(--answer-back);
  border-radius: 10px;
}
.body__bottom__input__text {
  background-color: var(--answer-back);
  color: var(--text-answer);
  resize: none;
  width: 100%;
  max-height: 80px;
  overflow: scroll;
  box-sizing: border-box;
  border: none;
  outline: none;
  line-height: 1.5rem;
  padding: 10px;
  border-radius: 10px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
::-webkit-scrollbar {
  display: none;
}
.body__bottom__input__icon {
  background-color: var(--light);
  color: var(--primary);
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 10px;
}
.body__bottom__text {
  color: var(--text-answer);
}
/* body end */

/* login start */
.login {
  height: 100vh;
  width: 100%;
}
.login__right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.login__right__logo {
  width: 60%;
  position: absolute;
  top: 20px;
}
.login__right__title {
  font-size: 2rem;
}

.login__right__single {
  display: flex;
  flex-direction: column;
  width: 70vw;
  margin: 1rem 0;
}
.login__right__single input {
  border: 1px solid var(--primary);
  border-radius: 20px;
  padding: 0 15px;
}
.login__left__title {
  font-weight: bold;
  font-size: 4rem;
  color: var(--light);
}
#login {
  background-color: var(--primary);
  color: var(--light);
}
#reset {
  border: none;
  color: var(--primary);
  background-color: none;
}
/* login end */

.audio__label{
  color: var(--primary);
  background-color: var(--light);
  border-radius: 10px;
  margin: 8px 1rem;
  padding: 0 1rem;
  /* font-weight: bold; */
  
}
.audio__file__name{
  color: var(--text-answer);
}
@media only screen and (min-width: 992px) {
  /* menu start */
  .menu {
    background-color: var(--primary);
    height: 100%;
    color: var(--light);
    position: fixed;
    width: 25vw;
    z-index: 101;
  }
  .menu__logo {
    width: 100%;
    padding: 10% 35%;
    z-index: 50;
  }
  .menu__items {
    position: static;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    padding: 10px;
    height: auto;
  }
  .menu__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 100%;
  }
  .menu__item__icon {
    font-size: 25px;
    padding: 0px 10px !important;
    display: flex;
    align-self: center;
  }
  .menu__item__text {
    display: block;
    color: white !important;
    width: 100%;
    padding-right: 20px;
  }
  #hide {
    display: none !important;
  }
  .back_to_home {
    display: none !important;
  }
  .back .back_to_home {
    display: block !important;
  }
  .menu__item__text__drop {
    display: block;
  }
  .menu_account .menu__item__text {
    display: block;
  }
  .menu_account {
    position: fixed;
    bottom: 15px;
  }
  .tempHide {
    display: block !important;
  }
  .temp-slider {
    width: 100% !important;
    position: static;
  }
  .temprature_value {
    position: static !important;
    display: block !important;
  }
  .temprature_section {
    height: 5rem;
  }
  .saved__list__items {
    height: 60vh !important;
    position: static;
  }
  .dropdown,
  .dropdown-menu {
    background-color: none !important;
    width: 100%;
    color: white !important;
    max-height: 40vh;
  }
  .dropdown {
    width: 100%;
  }
  .dropdown .btn {
    width: 100%;
  }
  .dropdown-default {
    font-size: 1.5rem;
    font-weight: 300;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap; 
    overflow: hidden; 
  }
  .dropdown-toggler {
    display: flex;
    justify-content: space-between;
    padding: 50px;
  }
  /* body start */
 
  .body__chat {
    margin-top: 0px;
    padding-bottom: 130px;
    min-height: 90vh;
    
    
  }
  .chat__bottom__scroll{
    height:120px
  }
 
  .body__mode {
    color: var(--text-answer) !important;
    position: fixed;
    bottom: 15px;
    right: 15px;
    height: 48px;
    display: flex;
    font-size: 2rem;
  }
  .body__btns {
    height: 40px;
    width: 100%;
    background-color: var(--back);
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
  }
  .body__menu {
    top: 15px;
    left: 40%;
    background-color: rgba(0, 0, 0, 0);
    width: auto;
    height: 50px;
  }
  .body__btn {
    background-color: var(--light);
    display: flex;
    margin: 0 5%;
    padding: 5px 20px;
    color: var(--primary);
    border-radius: 10px;
  }
  .body__btn__text {
    display: block;
    padding: 0 15px;
  }
  .body__chat__answer__icon img {
    color: red;
    fill: red !important;
  }
  .body__bottom {
    position: fixed;
    bottom: 0px;
    width: 75%;
    background-color: var(--back);
    padding: 10px 20px;
  }
  .clear__btn {
    width: 50px !important;
    height: 50px;
  }
  .clear__btn:hover {
    border: 2px solid var(--primary);
    scale: 110%;
    transition: 0.1s ease-in-out;
  }
  /* body start */
  /* login start */
  .login {
    display: grid;
    grid-template-columns: 1fr 2fr;
    height: 100vh;
  }

  .login__left {
    background-color: var(--primary);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .login__left__logo {
    margin: auto;
    height: 3vw;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .login__left__bottom {
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .login__left__img {
    width: 70%;
  }
  .login__right__single {
    width: 35vw;
  }
  /* login end */
}
@media only screen and (min-width: 1200px) {
  .menu {
    width: 16.66vw;
  }
  .body__bottom {
    position: fixed;
    bottom: 0px;
    width: 83.33%;
  }
}
