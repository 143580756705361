:root .light{
  --primary:#7F0707;
  --loader:#7F0707;
  --text-answer:black;
  --text-question:white;
  --light:white;
  --back:rgb(255, 255, 255);
  --answer-back:rgb(200, 200, 200);
  
}
:root .dark{
  --loader:white;
 
  --text-answer:white;
  --text-question:black;
  --answer-back:rgb(91, 91, 91);
  --back:rgb(53, 52, 52);
}


  @import url('https://fonts.googleapis.com/css2?family=Dongle:wght@300;400;700&display=swap');

body {
  margin: 0;
  font-family: 'Dongle', sans-serif !important;
  font-weight: 300 !important;
  font-size: 1.5rem !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

 
.light .for__light{
  display:block !important;
}
.dark .for__light{
  display:none !important;
}
.dark .for__light{
  display:none !important;
}
.dark .for__dark{
  
  display:block !important;
}
.loader-inner div {
  background: var(--loader) !important;
}
.toolTip {
  font-size: 0.9rem;
  font-weight: normal;
}